import arrayMove from "array-move";
import $ from "jquery";
import moment from "moment";
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { chartData, chartDataRaw } from "../../api/chart";
import { SendEmail } from "../../api/email";
import { features, groupChartPrefKeys, groupChartTierPrefKeys, isFeatureEnabled } from "../../config";
import { UserPreferencesContext } from "../../context/userPreferences";
import { formatISO } from "../../helpers/dateTimeHelper";
import { logEvent } from "../../helpers/ga";
import useWindowSize from "../../hooks/useWindowSize";
import CanvasJSReact from "../../lib/canvasjs.react";
import local from "../../localization/strings";
import EmailModal from "../common/EmailModal";
import Loader from "../common/Loader";
import ChartCustomThresholds from "./ChartCustomThresholds";
import ChartNote from "./ChartNote";
import ChartOverlay from "./ChartOverlay";
import ChartPeriod from "./ChartPeriod";
import ChartRanges from "./ChartRanges";
import ChartSummary from "./ChartSummary";
import ChartToolbarMain from "./ChartToolbarMain";
import ChartToolbarNav from "./ChartToolbarNav";
import DataSeriesOptions from "./DataSeriesOptions";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const GroupChart = ({ zoneId, group, chartIndex, isMaximised, setIsMaximise, isCurrentTab, toggleTooltip, tooltip, focusOn, updateTabName }) => {
	const [showLoading, setShowLoading] = useState(true);
	const [options, setOptions] = useState();
	const [currentChartTitle, setCurrentChartTitle] = useState();
	const [period, setPeriod] = useState();
	const [mode, setMode] = useState("Chart");
	const [overlays, setOverlays] = useState([]);
	const [showRanges, setShowRanges] = useState(false);
	const [showPeriod, setShowPeriod] = useState(false);
	const [applyPeriodChangesTo, setApplyPeriodChangesTo] = useState("Chart");
	const [addingNote, setAddingNote] = useState(false);
	const [showAddNote, setShowAddNote] = useState(false);
	const [notePoint, setNotePoint] = useState({});
	const [showEmail, setShowEmail] = useState(false);
	const [showThresholds, setShowThresholds] = useState(true);
	const [showNotes, setShowNotes] = useState(true);
	const [noteSize, setNoteSize] = useState("Large");
	const [compareIds, setCompareIds] = useState([]);
	const [compareOffset, setCompareOffset] = useState(0.0);
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const [compareFrom, setCompareFrom] = useState(null);
	const [compareTo, setCompareTo] = useState(null);
	const [chartFrom, setChartFrom] = useState(null);
	const [chartTo, setChartTo] = useState(null);
	const [chartCompareFrom, setChartCompareFrom] = useState(null);
	const [chartCompareTo, setChartCompareTo] = useState(null);
	const [rangeOverride, setRangeOverride] = useState({});
	const [customThresholds, setCustomThresholds] = useState();
	const [showSummary, setShowSummary] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const mouseDownRef = useRef();
	const { prefsObj, savePreference, saveTierPreference, prefsLoaded } = useContext(UserPreferencesContext);
	const [legendId, setLegendId] = useState();
	const prefsRef = useRef();

	function processAction(action, extra1, extra2, extra3, extra4) {
		logEvent("Zone Charts", `Action: ${action}`);

		switch (action) {
			case "SetApplyPeriodChangesTo_Chart":
				savePreference("Chart_ApplyPeriodChangesTo", "Chart");
				break;
			case "SetApplyPeriodChangesTo_Zone":
				savePreference("Chart_ApplyPeriodChangesTo", "Zone");
				break;
			case "SetApplyPeriodChangesTo_Site":
				savePreference("Chart_ApplyPeriodChangesTo", "Site");
				break;
			case "ToggleStripLine":
				savePreference("Chart_ShowThresholds", !showThresholds);
				break;
			case "ToggleNotes":
				savePreference("Chart_ShowNotes", !showNotes);
				break;
			case "ShowPeriod":
				setShowPeriod(true);
				break;
			case "ClosePeriod":
				setShowPeriod(false);
				break;
			case "ClearPeriod":
				setShowPeriod(false);
				setFrom(null);
				setTo(null);
				saveTierPreference(applyPeriodChangesTo, zoneId, group.groupId, chartIndex, "Chart_TierPeriod", "Week");
				break;
			case "ApplyComparePeriod":
				setCompareFrom(extra1);
				setCompareTo(extra2);
				setCompareOffset(0.0);
				break;
			case "ApplyPeriod":
				setCompareFrom(extra3);
				setCompareTo(extra4);
				setOffset(0.0);
				setCompareOffset(0.0);
				setShowPeriod(false);
				setShowLoading(true);
				saveTierPreference(applyPeriodChangesTo, zoneId, group.groupId, chartIndex, "Chart_TierPeriod", `Custom:${JSON.stringify({ from: formatISO(extra1), to: formatISO(extra2) })}`);
				break;
			case "ShowRanges":
				setShowRanges(true);
				break;
			case "CloseRanges":
				setShowRanges(false);
				break;
			case "ClearRanges":
				if (options.axisY) {
					options.axisY.minimum = options.axisY.minimumOriginal;
					options.axisY.maximum = options.axisY.maximumOriginal;
				}
				if (options.axisY2) {
					options.axisY2.minimum = options.axisY2.minimumOriginal;
					options.axisY2.maximum = options.axisY2.maximumOriginal;
				}
				setOptions(options);
				ref.render();
				saveTierPreference("Chart", zoneId, group.groupId, chartIndex, "Chart_Range", JSON.stringify({}));
				setRangeOverride({});
				setShowRanges(false);
				break;
			case "ApplyRanges":
				const newRangeOverride = {};
				if (options.axisY) {
					options.axisY.minimum = extra1.min;
					options.axisY.maximum = extra1.max;
					newRangeOverride.axisYMinimum = extra1.min;
					newRangeOverride.axisYMaximum = extra1.max;
				}
				if (options.axisY2) {
					options.axisY2.minimum = extra2.min;
					options.axisY2.maximum = extra2.max;
					newRangeOverride.axisY2Minimum = extra2.min;
					newRangeOverride.axisY2Maximum = extra2.max;
				}
				setOptions(options);
				ref.render();
				setShowRanges(false);
				saveTierPreference("Chart", zoneId, group.groupId, chartIndex, "Chart_Range", JSON.stringify(newRangeOverride));
				setRangeOverride(newRangeOverride);
				break;
			case "Day":
			case "Week":
			case "Month":
			case "Year":
				setPeriod(action);
				saveTierPreference(applyPeriodChangesTo, zoneId, group.groupId, chartIndex, "Chart_TierPeriod", action);
				break;
			case "Reset":
				setPeriod("Week");

				Object.keys(groupChartTierPrefKeys).forEach((k) => {
					savePreference(groupChartTierPrefKeys[k], "");
				});

				Object.keys(groupChartPrefKeys).forEach((k) => {
					savePreference(groupChartPrefKeys[k], "");
				});
				break;
			case "ShowCustomThresholds":
				setShowLoading(true);
				setMode("CustomThresholds");
				break;
			case "ShowOverlay":
				setShowLoading(true);
				setMode("Overlay");
				break;
			case "ShowSummary":
				setRaw({ loading: true });
				if (ref && ref.axisX && ref.axisX[0]) {
					let obj = { fromDate: ref.axisX[0].viewportMinimum, toDate: ref.axisX[0].viewportMaximum };
					if (ref.axisX2 && ref.axisX2[0]) {
						obj.compareFromDate = ref.axisX2[0].viewportMinimum;
						obj.compareToDate = ref.axisX2[0].viewportMaximum;
					}
					fetchDataSummaryCallback(obj);
				} else {
					fetchDataSummaryCallback();
				}
				setShowSummary(true);
				break;
			case "ShowCompare":
				setShowLoading(true);
				setMode("Compare");
				break;
			case "HideSummary":
				setShowSummary(false);
				break;
			case "HideCustomThresholds":
			case "HideOverlay":
			case "HideCompare":
				setShowLoading(true);
				setMode("Chart");
				var div = document.getElementsByClassName("react-tabs__tab-panel")[0];
				if (compareIds.length > 0) {
					div.classList.add("extra-height");
				} else {
					div.classList.remove("extra-height");
				}
				break;
			case "Maximise":
				setIsMaximise(true);
				break;
			case "Minimise":
				setIsMaximise(false);
				break;
			case "AddNote":
				options.zoomEnabled = false;
				setOptions(options);
				setAddingNote(true);
				break;
			case "CancelAddNote":
				options.zoomEnabled = true;
				setOptions(options);
				setAddingNote(false);
				setShowAddNote(false);
				break;
			case "SaveAddNote":
				options.zoomEnabled = true;
				setOptions(options);
				setAddingNote(false);
				setShowAddNote(false);
				refresh();
				break;
			case "ShowEmail":
				setShowEmail(true);
				break;
			case "CancelEmail":
				setShowEmail(false);
				break;
			case "SmallNotes":
				savePreference("Chart_NoteSize", "Small");
				break;
			case "MediumNotes":
				savePreference("Chart_NoteSize", "Medium");
				break;
			case "LargeNotes":
				savePreference("Chart_NoteSize", "Large");
				break;
			case "ApplyOverlays":
				saveTierPreference("Chart", zoneId, group.groupId, chartIndex, "Chart_Overlays", JSON.stringify(extra1));
				break;
			case "UpdateCustomThresholds":
				saveTierPreference("Chart", zoneId, group.groupId, chartIndex, groupChartTierPrefKeys.customThresholds, JSON.stringify(extra1));
				break;
			default:
				break;
		}
	}

	// Preference logic to update charts when things like Period and Show Thresholds change
	useEffect(() => {
		// Only update the current tab, this will make any needed adjustments to other tabs as you switch to them
		if (!isCurrentTab) {
			return;
		}

		// Need to make sure Prefs have loaded
		if (!prefsLoaded) {
			return;
		}

		prefsRef.current = prefsObj;

		const periodsObject = JSON.parse(prefsObj?.ChartTierPeriod || "{}");
		let prefValue = periodsObject["S"] || "Week";
		if (periodsObject[`S/${zoneId}/${group.groupId}/${chartIndex}`]) {
			prefValue = periodsObject[`S/${zoneId}/${group.groupId}/${chartIndex}`];
		} else {
			if (periodsObject[`S/${zoneId}`]) {
				prefValue = periodsObject[`S/${zoneId}`];
			}
		}
		if (prefValue !== (period || "")) {
			if (prefValue.startsWith("Custom:")) {
				const data = JSON.parse(prefValue.replace("Custom:", ""));
				setFrom(moment(data.from).toDate());
				setTo(moment(data.to).toDate());
				setPeriod("Custom");
			} else {
				setPeriod(prefValue);
			}
		}

		const overlaysObject = JSON.parse(prefsObj?.ChartOverlays || "{}");
		let prefValueOverlays = overlaysObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "[]";
		if (prefValueOverlays !== JSON.stringify(overlays)) {
			setOverlays(JSON.parse(prefValueOverlays));
		}

		const rangeObject = JSON.parse(prefsObj?.ChartRange || "{}");
		let prefValueRange = rangeObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "{}";
		if (prefValueRange !== JSON.stringify(rangeOverride)) {
			setRangeOverride(JSON.parse(prefValueRange));
		}

		if (applyPeriodChangesTo !== (prefsObj.ChartApplyPeriodChangesTo || "Chart")) {
			setApplyPeriodChangesTo(prefsObj.ChartApplyPeriodChangesTo || "Chart");
		}

		if (showThresholds !== ((prefsObj.ChartShowThresholds || "true") === "true")) {
			setShowThresholds((prefsObj.ChartShowThresholds || "true") === "true");
		}

		if (showNotes !== ((prefsObj.ChartShowNotes || "true") === "true")) {
			setShowNotes((prefsObj.ChartShowNotes || "true") === "true");
		}

		if (noteSize !== (prefsObj.ChartNoteSize || "Medium")) {
			setNoteSize(prefsObj.ChartNoteSize || "Medium");
		}

		const customThresholdsObject = JSON.parse(prefsObj?.ChartCustomThresholds || "{}");
		let prefValueCustomThresholds = customThresholdsObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "[]";
		if (prefValueCustomThresholds !== JSON.stringify(customThresholds)) {
			setCustomThresholds(JSON.parse(prefValueCustomThresholds));
		}
	}, [prefsLoaded, prefsObj, zoneId, group.groupId, chartIndex, period, showThresholds, showNotes, noteSize, isCurrentTab, rangeOverride, overlays, applyPeriodChangesTo, customThresholds]);

	const [raw, setRaw] = useState({});
	const [ref, setRef] = useState({});
	const [offset, setOffset] = useState(0.0);
	const size = useWindowSize();

	const refresh = () => {
		logEvent("Zone Charts", "Refresh");

		fetchDataCallback();
	};

	const download = () => {
		logEvent("Zone Charts", "Download");

		const now = new Date();
		ref.exportChart({ format: "jpg", fileName: `ZoneChart_${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, 0)}${String(now.getDate()).padStart(2, "0")}_${String(now.getHours()).padStart(2, "0")}${String(now.getMinutes()).padStart(2, "0")}` });
	};

	const print = () => {
		logEvent("Zone Charts", "Print");

		ref.print();
	};

	const email = () => {
		logEvent("Zone Charts", "Email");

		processAction("ShowEmail");
	};

	function SelectText(element) {
		var doc = document;
		if (doc.body.createTextRange) {
			var range = document.body.createTextRange();
			range.moveToElementText(element);
			range.select();
		} else if (window.getSelection) {
			var selection = window.getSelection();
			var selectionRange = document.createRange();
			selectionRange.selectNodeContents(element);
			selection.removeAllRanges();
			selection.addRange(selectionRange);
		}
	}

	const copy = () => {
		logEvent("Zone Charts", "Copy");

		var canvasDataUrl = ref.exportChart({ format: "png", toDataURL: true });

		var img = document.createElement("img");
		img.src = canvasDataUrl;

		var div = document.createElement("div");
		div.contentEditable = true;
		div.appendChild(img);
		document.body.appendChild(div);

		// do copy
		SelectText(div);
		document.execCommand("Copy");
		document.body.removeChild(div);
		toast.success(local.TF_item_was_copied);
	};

	const sendEmail = async (to, subject, body) => {
		logEvent("Zone Charts", "Send Email", `To: ${to}, Subject: ${subject}`);

		var canvasDataUrl = ref.exportChart({ format: "png", toDataURL: true });

		const base64Loc = canvasDataUrl.indexOf("base64,");

		await SendEmail(to, subject, body, canvasDataUrl.substring(base64Loc + 7));

		setShowEmail(false);
	};

	const adjustOffset = (action, updateOffset, updateCompare) => {
		logEvent("Zone Charts", `Adjust Offset: ${action}`, `Main: ${updateOffset}, Compare: ${updateCompare}`);

		switch (action) {
			case "<":
				updateOffset && setOffset(offset - 0.25);
				updateCompare && setCompareOffset(compareOffset - 0.25);
				break;
			case "<<":
				updateOffset && setOffset(offset - 1.0);
				updateCompare && setCompareOffset(compareOffset - 1.0);
				break;
			case ">>":
				updateOffset && setOffset(offset + 1.0);
				updateCompare && setCompareOffset(compareOffset + 1.0);
				break;
			case ">":
				updateOffset && setOffset(offset + 0.25);
				updateCompare && setCompareOffset(compareOffset + 0.25);
				break;
			default:
				updateOffset && setOffset(0.0);
				updateCompare && setCompareOffset(0.0);
				break;
		}
	};

	function chartZoomed(e, ref) {
		const secondFormat = isFeatureEnabled(features.Seconds) ? ":ss" : "";

		function dateFormatString(min, max) {
			if (!min || !max) {
				return "DD MMM YY";
			}

			const days = (max - min) / 1000.0 / 86400.0;

			if (days > 3) {
				return "DD MMM YY";
			}

			if (days > 1) {
				return "DD MMM HH:mm" + secondFormat;
			}

			if (days > 0.1) {
				return "HH:mm" + secondFormat;
			}

			return "HH:mm:ss";
		}

		function dateFormatStringLong(min, max) {
			if (!min || !max) {
				return "DD MMM YY HH:mm" + secondFormat;
			}

			const days = (max - min) / 1000.0 / 86400.0;

			if (days > 0.1) {
				return "DD MMM YY HH:mm" + secondFormat;
			}

			return "DD MMM YY HH:mm:ss";
		}

		if (ref && ref.axisX && ref.axisX[0]) {
			if (e.trigger === "reset") {
				ref.subtitles[0].set("text", `${moment(e.chart.axisX[0].viewportMinimum).format("DD MMM YYYY HH:mm" + secondFormat)} - ${moment(e.chart.axisX[0].viewportMaximum).format("DD MMM YYYY HH:mm" + secondFormat)}`);
				ref.axisX[0].set("valueFormatString", dateFormatString(e.chart.axisX[0].viewportMinimum, e.chart.axisX[0].viewportMaximum));
				ref.data[0].set("xValueFormatString", dateFormatStringLong(e.chart.axisX[0].viewportMinimum, e.chart.axisX[0].viewportMaximum));
				if (ref.axisX2 && ref.axisX2[0]) {
					ref.subtitles[1].set("text", `${local.TF_Compare}: ${moment(e.chart.axisX2[0].viewportMinimum).format("DD MMM YYYY HH:mm" + secondFormat)} - ${moment(e.chart.axisX2[0].viewportMaximum).format("DD MMM YYYY HH:mm" + secondFormat)}`);
					ref.axisX2[0].set("valueFormatString", dateFormatString(e.chart.axisX2[0].viewportMinimum, e.chart.axisX2[0].viewportMaximum));
				}
			} else {
				if (e.axisX[0].viewportMinimum && e.axisX[0].viewportMaximum) {
					ref.subtitles[0].set("text", `${moment(e.axisX[0].viewportMinimum).format("DD MMM YYYY HH:mm" + secondFormat)} - ${moment(e.axisX[0].viewportMaximum).format("DD MMM YYYY HH:mm" + secondFormat)}`);
				}
				ref.axisX[0].set("valueFormatString", dateFormatString(e.axisX[0].viewportMinimum, e.axisX[0].viewportMaximum));
				ref.data[0].set("xValueFormatString", dateFormatStringLong(e.axisX[0].viewportMinimum, e.axisX[0].viewportMaximum));
				if (ref.axisX2 && ref.axisX2[0]) {
					if (e.axisX2[0].viewportMinimum && e.axisX2[0].viewportMaximum) {
						ref.subtitles[1].set("text", `${local.TF_Compare}: ${moment(e.axisX2[0].viewportMinimum).format("DD MMM YYYY HH:mm" + secondFormat)} - ${moment(e.axisX2[0].viewportMaximum).format("DD MMM YYYY HH:mm" + secondFormat)}`);
					}
					ref.axisX2[0].set("valueFormatString", dateFormatString(e.axisX2[0].viewportMinimum, e.axisX2[0].viewportMaximum));
				}
			}
		}

		setIsZoomed(e.trigger !== "reset");
	}

	const legendClicked = useCallback((e) => {
		setLegendId(e.dataSeries.locationId);
	}, []);

	const fetchDataSummaryCallback = useCallback(
		async (zoomObj) => {
			var rawResult = await chartDataRaw(group.groupId, chartIndex, period, offset, overlays.join(), compareOffset, compareIds.join(), from, to, compareFrom, compareTo);

			if (rawResult.success) {
				rawResult.data.title = currentChartTitle;
				if (zoomObj) {
					rawResult.data.rows = rawResult.data.rows.reduce((result, item) => {
						if (item.SetType === "Compare") {
							if (moment(item.DateTime).isSameOrBefore(moment(zoomObj.compareToDate)) && moment(item.DateTime).isSameOrAfter(moment(zoomObj.compareFromDate))) {
								result.push(item);
							}
						} else {
							if (moment(item.DateTime).isSameOrBefore(moment(zoomObj.toDate)) && moment(item.DateTime).isSameOrAfter(moment(zoomObj.fromDate))) {
								result.push(item);
							}
						}

						return result;
					}, []);
				}

				rawResult.data.columns[0].customSort = (a, b) => {
					if (a.DateTime < b.DateTime) {
						return -1;
					}
					if (a.DateTime > b.DateTime) {
						return 1;
					}
					return 0;
				};

				rawResult.data.style = { width: 320 };
				rawResult.data.columns.map(function(item) {
					return (item.cellStyle = { width: 50 });
				});
				rawResult.data.columns[0].cellStyle = { width: 220 };

				const formatString = isFeatureEnabled(features.Seconds) ? "DD MMM YYYY HH:mm:ss" : "DD MMM YYYY HH:mm";

				const minDate = Math.min(...rawResult.data.rows.map((e) => new Date(e.DateTime)));
				const maxDate = Math.max(...rawResult.data.rows.map((e) => new Date(e.DateTime)));

				if (minDate && maxDate && !isNaN(minDate) && !isNaN(maxDate)) {
					rawResult.data.exportTitle = `Chart Summary - ${moment(minDate).format(formatString)} - ${moment(maxDate).format(formatString)} - ${rawResult.data.title}`;
				} else {
					rawResult.data.exportTitle = `Chart Summary - ${rawResult.data.title}`;
				}

				rawResult.data.columns.forEach((column) => {
					if (column.field === "DateTime") {
						column.render = (rowData) => {
							return <>{moment(new Date(rowData["DateTime"])).format(formatString)}</>;
						};
					}
					if (column.decimalPlaces) {
						column.render = (rowData) => {
							if (rowData[column.field] || typeof rowData[column.field] === "number") {
								return <>{rowData[column.field].toFixed(column.decimalPlaces)}</>;
							} else {
								return null;
							}
						};
					}
				});

				setRaw(rawResult.data);
			}
		},
		[group, chartIndex, period, offset, overlays, compareIds, compareOffset, from, to, compareFrom, compareTo, currentChartTitle],
	);

	const buildTitle = (data, alwaysShowZones, showParameters) => {
		//filter out hidden and notes (zoneName null)
		const visibleData = data.filter((d) => d.visible !== false && d.zoneName);

		//unflatten options data
		const zones = [...new Set(visibleData.map((item) => item.zoneName))].map((z) => {
			return {
				name: z,
				groups: [...new Set(visibleData.filter((x) => x.zoneName === z).map((f) => f.groupName))].map((g) => {
					return {
						name: g,
						parameters: [...new Set(visibleData.filter((x) => x.groupName === g).map((f) => f.parameterName))],
					};
				}),
			};
		});

		//Display the zones, Groups and Parameters
		const zonesDisplay = zones.map((z) => {
			const groupsDisplay = z.groups.map((g) => (showParameters ? `${g.name} (${g.parameters.join("|")})` : g.name));
			return (alwaysShowZones || zones.length > 1 ? `${z.name} - ` : "") + groupsDisplay.join(", ");
		});
		return zonesDisplay.join(". ");
	};

	const fetchDataChartCallback = useCallback(async () => {
		if (!period) {
			return;
		}

		if (period === "Custom" && !from) {
			return;
		}

		var result = await chartData(group.groupId, chartIndex, period, offset, overlays.join(), compareOffset, compareIds.join(), from, to, "GroupChart", compareFrom, compareTo, isFeatureEnabled(features.Seconds));

		if (result.success) {
			result.data.data.forEach((element) => {
				//Remove tooltip if it's a notes series
				if (element.name === "Notes") {
					element.toolTipContent = null;
					element.visible = showNotes;
				}

				element.dataPoints.forEach((point) => {
					point.x = new Date(point.x);
				});
			});

			if (result.data.axisY) {
				if (rangeOverride?.axisYMinimum || typeof rangeOverride?.axisYMinimum === "number") {
					result.data.axisY.minimum = rangeOverride.axisYMinimum;
					result.data.axisY.maximum = rangeOverride.axisYMaximum;
				}

				result.data.axisY.stripLines.forEach((stripLine) => {
					stripLine.opacity = showThresholds ? stripLine.opacityOriginal : 0;
				});
			}

			if (result.data.axisY2) {
				if (rangeOverride?.axisY2Minimum || typeof rangeOverride?.axisY2Minimum === "number") {
					result.data.axisY2.minimum = rangeOverride.axisY2Minimum;
					result.data.axisY2.maximum = rangeOverride.axisY2Maximum;
				}

				result.data.axisY2.stripLines.forEach((stripLine) => {
					stripLine.opacity = showThresholds ? stripLine.opacityOriginal : 0;
				});
			}

			if (result.data.axisX) {
				result.data.axisX.crosshair.snapToDataPoint = true;
				result.data.axisX.labelFontSize = 12;

				if (result.data.axisX.minimum) {
					result.data.axisX.minimum = new Date(result.data.axisX.minimum);
					setChartFrom(new Date(result.data.axisX.minimum));
				}

				if (result.data.axisX.maximum) {
					result.data.axisX.maximum = new Date(result.data.axisX.maximum);
					setChartTo(new Date(result.data.axisX.maximum));
				}
			}
			if (result.data.axisX2) {
				result.data.axisX2.crosshair.snapToDataPoint = true;
				result.data.axisX2.labelFontSize = 12;

				if (result.data.axisX2.minimum) {
					result.data.axisX2.minimum = new Date(result.data.axisX2.minimum);
					setChartCompareFrom(new Date(result.data.axisX2.minimum));
				}

				if (result.data.axisX2.maximum) {
					result.data.axisX2.maximum = new Date(result.data.axisX2.maximum);
					setChartCompareTo(new Date(result.data.axisX2.maximum));
				}
			}

			if (result.data.axisY) {
				result.data.axisY.crosshair.snapToDataPoint = true;
			}
			if (result.data.axisY2) {
				result.data.axisY2.crosshair.snapToDataPoint = true;
				// result.data.axisY2.gridDashType = "dash"; Instead of dashes, made the grid invisible
				result.data.axisY2.gridThickness = 0;
			}

			result.data.data.forEach((x) => {
				x.xValueFormatString = isFeatureEnabled(features.Seconds) ? "DD MMM YY HH:mm:ss" : "DD MMM YY HH:mm";

				if (x.type === "line") {
					switch (noteSize) {
						case "Small":
							x.indexLabelFontSize = 10;
							break;
						case "Medium":
							x.indexLabelFontSize = 14;
							break;
						case "Large":
						default:
							x.indexLabelFontSize = 18;
							break;
					}
				}
			});

			result.data.legend.cursor = "pointer";

			if (tooltip === "false") {
				result.data.toolTip.enabled = false;
				if (result.data.axisX) {
					result.data.axisX.crosshair = false;
				}
				if (result.data.axisX2) {
					result.data.axisX2.crosshair = false;
				}
				if (result.data.axisY) {
					result.data.axisY.crosshair = false;
				}
				if (result.data.axisY2) {
					result.data.axisY2.crosshair = false;
				}
			} else {
				result.data.toolTip.enabled = true;
			}

			result.data.legend.itemclick = legendClicked;

			//Any prefs need applying?
			const prefsObj = prefsRef.current;
			// - Visibility
			const visibilityObject = JSON.parse(prefsObj?.ChartVisibility || "{}");
			const prefValueVisibility = visibilityObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "[]";
			JSON.parse(prefValueVisibility).forEach((v) => {
				const parts = v.split(":");
				if (parts.length > 1 && parts[1] === "false") {
					const found = result.data.data.find((x) => x.locationId === parseInt(parts[0]));
					if (found) {
						found.visible = false;
					}
				}
			});
			// - Colour
			const colourObject = JSON.parse(prefsObj?.ChartColour || "{}");
			const prefValueColour = colourObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "[]";
			JSON.parse(prefValueColour).forEach((v) => {
				const parts = v.split(":");
				if (parts.length > 1) {
					const found = result.data.data.find((x) => x.locationId === parseInt(parts[0]));
					if (found) {
						if (found.legendMarkerBorderColor) {
							found.legendMarkerBorderColor = parts[1];
						}
						found.legendMarkerColor = parts[1];
						found.lineColor = parts[1];
						found.color = parts[1];
					}
				}
			});
			// - Order
			const orderObject = JSON.parse(prefsObj?.ChartOrder || "{}");
			const prefValueOrder = orderObject[`S/${zoneId}/${group.groupId}/${chartIndex}`] || "[]";
			let position = 0;
			JSON.parse(prefValueOrder).forEach((v) => {
				const foundIndex = result.data.data.findIndex((x) => x.locationId === parseInt(v));
				if (foundIndex > -1) {
					result.data.data = arrayMove(result.data.data, foundIndex, position);

					++position;
				}
			});

			const tabName = buildTitle(result.data.data, false, true);
			updateTabName(group.groupId, chartIndex, tabName);

			const chartTitle = buildTitle(result.data.data, true, false);
			result.data.title.text = chartTitle;

			//Custom thresholds
			if (customThresholds) {
				customThresholds.forEach((ct) => {
					if (ct.plotted) {
						let lineDashType = "solid";
						switch (ct.plotStyle) {
							case "1":
							case 1:
								lineDashType = "dash";
								break;
							case "2":
							case 2:
								lineDashType = "dot";
								break;
							case "3":
							case 3:
								lineDashType = "dashDot";
								break;
							case "4":
							case 4:
								lineDashType = "longDashDotDot";
								break;
							default:
								lineDashType = "solid";
								break;
						}

						if (ct.axis === "0" && result.data.axisY) {
							if (!result.data.axisY.stripLines) {
								result.data.axisY.stripLines = [];
							}
							result.data.axisY.stripLines.push({
								value: ct.value,
								color: ct.colour,
								opacity: 1,
								opacityOriginal: 1,
								showOnTop: false,
								thickness: ct.lineThickness,
								lineDashType: lineDashType,
							});
						}
						if (ct.axis === "1" && result.data.axisY2) {
							if (!result.data.axisY2.stripLines) {
								result.data.axisY2.stripLines = [];
							}
							result.data.axisY2.stripLines.push({
								value: ct.value,
								color: ct.colour,
								opacity: 1,
								opacityOriginal: 1,
								showOnTop: false,
								thickness: ct.lineThickness,
								lineDashType: lineDashType,
							});
						}
					}
				});
			}

			setIsZoomed(false);
			setCurrentChartTitle(chartTitle);
			setOptions(result.data);
		}
	}, [zoneId, group, chartIndex, period, offset, overlays, showThresholds, compareIds, compareOffset, from, to, compareFrom, compareTo, tooltip, showNotes, noteSize, rangeOverride, legendClicked, updateTabName, customThresholds]);

	const fetchDataCallback = useCallback(async () => {
		setShowLoading(true);
		if (mode === "Chart") {
			await fetchDataChartCallback();
		}

		setShowLoading(false);
	}, [mode, fetchDataChartCallback]);

	useEffect(() => {
		if (focusOn) {
			var focusOnMoment = moment(parseInt(focusOn));
			setFrom(
				focusOnMoment
					.clone()
					.subtract(84, "hours")
					.toDate(),
			);
			setTo(
				focusOnMoment
					.clone()
					.add(84, "hours")
					.toDate(),
			);
			setPeriod("Custom");
		} else {
			setFrom(null);
			setTo(null);
			//setPeriod("Week");
		}
	}, [focusOn]);

	useEffect(() => {
		fetchDataCallback();
	}, [fetchDataCallback]);

	useEffect(() => {
		if (isCurrentTab && ref && Object.keys(ref).length !== 0 && !showLoading) {
			ref.render();
			ref.set("rangeChanged", (e) => chartZoomed(e, ref));

			if (isZoomed) {
				$(".canvasjs-chart-toolbar").show();
			} else {
				$(".canvasjs-chart-toolbar").hide();
			}

			$(".canvasjs-chart-canvas")
				.off("mousedown")
				.off("mouseup");

			if (addingNote) {
				$(".canvasjs-chart-canvas").on({
					mousedown: function(e) {
						var parentOffset = $(this)
							.parent()
							.offset();
						var relX = e.pageX - parentOffset.left;
						var relY = e.pageY - parentOffset.top;

						var xValue = Math.round(ref.axisX[0].convertPixelToValue(relX));
						var yValue = null;
						if (ref.axisY.length > 0) {
							yValue = Math.round(ref.axisY[0].convertPixelToValue(relY));
						}
						var y2Value = null;
						if (ref.axisY2.length > 0) {
							y2Value = Math.round(ref.axisY2[0].convertPixelToValue(relY));
						}

						mouseDownRef.current = { leaderX: xValue, leaderY: yValue, leaderY2: y2Value };
					},
					mouseup: function(e) {
						var parentOffset = $(this)
							.parent()
							.offset();
						var relX = e.pageX - parentOffset.left;
						var relY = e.pageY - parentOffset.top;

						var xValue = Math.round(ref.axisX[0].convertPixelToValue(relX));
						var yValue = null;
						if (ref.axisY.length > 0) {
							yValue = Math.round(ref.axisY[0].convertPixelToValue(relY));
						}
						var y2Value = null;
						if (ref.axisY2.length > 0) {
							y2Value = Math.round(ref.axisY2[0].convertPixelToValue(relY));
						}

						setNotePoint({ x: xValue, y: yValue, y2: y2Value, ...mouseDownRef.current });
						setShowAddNote(true);
					},
				});
			}
		}
	}, [isCurrentTab, ref, showLoading, isZoomed, addingNote, size, isMaximised, noteSize, options]);

	return (
		<Fragment>
			<div id="temp" />
			<ChartToolbarMain mode={mode} action={(action) => processAction(action)} isMaximised={isMaximised} addingNote={addingNote} toggleTooltip={toggleTooltip} showThresholds={showThresholds} showNotes={showNotes} showTooltips={tooltip === "true"} period={period} applyPeriodChangesTo={applyPeriodChangesTo} />
			{showLoading ? (
				<Loader extraclassname="loader-chartSized" />
			) : mode === "Compare" ? (
				<ChartOverlay onChange={(locationIds) => setCompareIds(locationIds)} overlays={compareIds} isMaximised={isMaximised} isCompare options={{ compareFrom: compareFrom || chartFrom, compareTo: compareTo || chartTo }} updateRange={(from, to) => processAction("ApplyComparePeriod", from, to)} />
			) : mode === "Overlay" ? (
				<ChartOverlay onChange={(locationIds) => processAction("ApplyOverlays", locationIds)} overlays={options.ids} isMaximised={isMaximised} />
			) : mode === "CustomThresholds" ? (
				<ChartCustomThresholds onChange={(extra1) => processAction("UpdateCustomThresholds", extra1)} customThresholds={customThresholds} />
			) : (
				<div id="chart-div" style={{ minHeight: 500 }} className="py-3 px-2">
					{options && (
						<>
							<CanvasJSChart containerProps={{ height: "90%", minHeight: "300px" }} options={options} onRef={(x) => setRef(x)} />
							<ChartToolbarNav groupId={group.groupId} onAdjustOffset={adjustOffset} onRefresh={refresh} offset={offset} onExport={download} onPrint={print} onCopy={copy} onEmail={email} compareIds={compareIds} />
							<ChartRanges show={showRanges} options={options} rangeOverride={rangeOverride} action={(action, extra1, extra2) => processAction(action, extra1, extra2)} />
							<ChartPeriod show={showPeriod} options={{ from: from || chartFrom, to: to || chartTo, compareFrom: compareFrom || chartCompareFrom, compareTo: compareTo || chartCompareTo }} action={(action, from, to, compareFrom, compareTo) => processAction(action, from, to, compareFrom, compareTo)} overlays={compareIds} />
							<ChartNote show={showAddNote} options={options} notePoint={notePoint} action={(action, note) => processAction(action, note)} groupId={group.groupId} graphIndex={chartIndex} />
							<EmailModal show={showEmail} sendEmail={sendEmail} cancel={() => setShowEmail(false)} />
							<ChartSummary show={showSummary} action={processAction} data={raw} customThresholds={customThresholds} />
						</>
					)}
				</div>
			)}
			<DataSeriesOptions locationId={legendId} onClose={() => setLegendId(undefined)} options={options} ref={ref} zoneId={zoneId} groupId={group.groupId} chartIndex={chartIndex} buildTitle={buildTitle} updateTabName={updateTabName} />
		</Fragment>
	);
};

export default GroupChart;
